import { FC, useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Grid, Link, Modal, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalForProposeTimeSlot from '../Applications/ModalForProposeTimeSlot';
import API from "../../api/API";
import STRINGS from '../../utils/Strings';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';


interface InterviewDetailProps {
    interviewData?: any;
    interviewSubmissionID?: any;
    candidateName?: any;
    candidateEmail?: any;
    setAvailabilities?: any;
    initialSlots?: any;
    slotId?: any;
    resumeVLNStatus?: any;
    updateDataAgain?: any;
    setUpdateDataAgain?: any;
    modifyMode?: any;
    description?: any;
    isTesting?: any
}

const InterviewDetail: FC<InterviewDetailProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [interviewAvailabilities, setInterviewAvailabilities] = useState<any[]>([]);
    const [selectedSlots, setSelectedSlots] = useState<any[]>([]);
    const [selectedNotes, setSelectedNotes] = useState('');
    const [slotId, setSlotId] = useState('');

    useEffect(() => {
        if (props.isTesting) {
            fetchInterviewData();
            handleOpenEdit([]);
            getTruncatedText("abcd");
            formatDuration("abcd");
            renderSlotCard();
            sortedInterviewData();
            handleOpen("abcd");
            calculateEndTime("abcd", "12");
            getSlotDetails("abcd");
            getSlotColor("abcd");
            handleClose();
            getSlotBorder("abcd");
            getSlotOpacity("abcd", "abcd");
            formatSlot("abcd");
        }
    }, []);

    useEffect(() => {
        fetchInterviewData();
    }, [props.interviewSubmissionID]);

    useEffect(() => {
        if (props.interviewData?.interviewAvailabilities) {
            setInterviewAvailabilities(props.interviewData.interviewAvailabilities || []);
        }
    }, [props.interviewData]);

    useEffect(() => {
        sortedInterviewData();
    }, [props.interviewData]);

    const sortedInterviewData = () => {
        if (props.interviewData?.interviewAvailabilities) {
            const sortedAvailabilities = props.interviewData.interviewAvailabilities.sort((a: any, b: any) => {
                const dateA = new Date(a.createdDate);
                const dateB = new Date(b.createdDate);

                if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                    return 0;
                }
                return dateB.getTime() - dateA.getTime();
            });
            setInterviewAvailabilities(sortedAvailabilities || []);
        }
    }


    const fetchInterviewData = async () => {
        try {
            const data = await API.getInterviewAvailabilitiesById(props.interviewSubmissionID);

            if (data && data.entityList) {
                setInterviewAvailabilities(data.entityList || []);
            } else {
                console.error("Failed to fetch interview data. No data returned.");
            }
        } catch (error) {
            console.error("Error fetching interview data:", error);
        }
    };


    const handleOpen = (fullDescription: string) => {
        setSelectedNotes(fullDescription);
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const handleOpenEdit = (availability: any) => {
        const selectedSlots = availability.currentAvailabilities || [];
        const selectedNotes = availability.description || '';
        setSelectedSlots(selectedSlots);
        setSelectedNotes(selectedNotes);
        setSlotId(availability.id);
        setOpenEdit(true);
    };

    const getTruncatedText = (text: any) => {
        const maxChars = 185;
        if (text?.length > maxChars) {
            return `${text?.slice(0, maxChars)}...`;
        }
        return text;
    };

    const getSlotBackground = (slot: any) => slot
        ? '#F8F8F8'
        : `repeating-linear-gradient(
        -45deg, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR}, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_COLOR} 3px, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 3px, 
        ${STRINGS.PROPOSE_TIME_SLOTS.EMPTY_SLOT_STRIPE_COLOR} 5px)`;

    const getSlotBorder = (slot: any) => slot && slot.isFinalized
        ? '1px solid #4A57A3'
        : '1px solid #E3E3E3';

    const getSlotOpacity = (isPastDate: any, resumeVLNStatus: any) => {
        const isDisabled = resumeVLNStatus === "Hired" || resumeVLNStatus === "Rejected"
        return isPastDate || isDisabled ? 0.5 : 1;
    }


    const getSlotColor = (slot: any) => (slot && slot.isFinalized ? "#4A57A3" : "#353535");

    const getSlotDetails = (slot: any) => {
        if (!slot) {
            return {
                isPastDate: false,
                isFinalized: false,
                key: `tooltip-slot-${Math.random()}`
            };
        }

        return {
            isPastDate: new Date(slot.interviewDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0),
            isFinalized: slot.isFinalized,
            key: slot.id
        };
    };

    const formatDuration = (duration: string) => {
        const hourMatch = duration.match(/(\d+)h/);
        const minuteMatch = duration.match(/(\d+)m/);

        let hours = hourMatch ? parseInt(hourMatch[1], 10) : 0;
        let minutes = minuteMatch ? parseInt(minuteMatch[1], 10) : 0;

        let formattedDuration = '';

        if (hours > 0) {
            formattedDuration += `${hours} hour${hours > 1 ? 's' : ''}`;
        }

        if (minutes > 0) {
            if (formattedDuration) formattedDuration += ' ';
            formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return formattedDuration;
    };

    const calculateEndTime = (startTime: any, duration: any) => {
        let [hours, minutes] = startTime.split(':').map(Number);

        let durationHours = 0;
        let durationMinutes = 0;

        const hourMatch = duration.match(/(\d+)h/);
        const minuteMatch = duration.match(/(\d+)m/);

        if (hourMatch) {
            durationHours = parseInt(hourMatch[1]);
        }
        if (minuteMatch) {
            durationMinutes = parseInt(minuteMatch[1]);
        }

        hours += durationHours;
        minutes += durationMinutes;

        if (minutes >= 60) {
            hours += Math.floor(minutes / 60);
            minutes = minutes % 60;
        }

        const endTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        return endTime;
    };

    const formatSlot = (slot: any) => {
        if (!slot) return null;
        const date = dayjs(slot.interviewDate).format(STRINGS.PROPOSE_TIME_SLOTS.DATE_FORMAT);
        const time = dayjs(slot.interviewTime, "HH:mm").format(STRINGS.PROPOSE_TIME_SLOTS.TIME_FORMAT);
        const duration = slot.interviewDuration || "1h";
        const borderColor = slot.isFinalized ? "#4A57A3" : "#D1D1D1";
        const fontColor = slot.isFinalized ? "#4A57A3" : "#353535";
        return { ...slot, date, time, duration, borderColor, fontColor };
    };

    const renderSlotCard = () => {
        return interviewAvailabilities.map((availability) => {

            const formattedSlots = availability.currentAvailabilities.map(formatSlot).filter(Boolean);

            formattedSlots.sort((a: any, b: any) => {
                const dateA = new Date(`${a.date} ${a.time}`).getTime();
                const dateB = new Date(`${b.date} ${b.time}`).getTime();
                return dateA - dateB;
            });
            const slotsToDisplay = formattedSlots.slice(0, 3);
            while (slotsToDisplay.length < 3) {
                slotsToDisplay.push(null);
            }

            const formattedDate = new Date(availability.createdDate).toLocaleDateString('en-US', {
                month: 'short', day: 'numeric', year: 'numeric',
            });

            return (
                <Grid
                    key={availability.id}
                    sx={{
                        border: "1px solid #E3E3E3",
                        borderRadius: "6px",
                        marginBottom: "5px",
                        width: "100%",
                        height: "184px",
                        aspectRatio: "1315 / 184",
                        '@media (max-width: 1315px)': {
                            width: "calc(100vw - 40px)",
                            margin: "0 20px 5px 20px",
                        },
                    }}
                >
                    <Typography variant='subtitle2' sx={{ margin: "20px", fontFamily: "Roboto", fontWeight: 700, fontSize: 20 }}>
                        Proposed Interview Slots  | {formattedDate}
                        <Tooltip title="Interview Slot Proposal Date" placement="top" arrow>
                            <InfoOutlinedIcon sx={{ marginLeft: "6px", verticalAlign: "middle", fontSize: 20, cursor: "pointer", color: "#5F6368" }} />
                        </Tooltip>
                    </Typography>
                    <Grid sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "16px",
                        flexWrap: "wrap",
                        '@media (max-width: 600px)': {
                            flexDirection: "column",
                            gap: "8px",
                        },
                        margin: "20px",
                    }}>
                        {slotsToDisplay.map((slot: any) => {
                            const startTimeFormatted = slot
                                ? `${slot.interviewTime.slice(0, 5)}`
                                : "00:00";

                            const { isPastDate, isFinalized, key } = getSlotDetails(slot);

                            const cardContent = (
                                <Card key={key} sx={{
                                    flexBasis: "16%",
                                    textAlign: "left",
                                    boxShadow: "none",
                                    background: getSlotBackground(slot),
                                    '& .MuiCardContent-root:last-child': {
                                        paddingBottom: 0
                                    },
                                    border: getSlotBorder(slot),
                                    opacity: getSlotOpacity(isPastDate, props.resumeVLNStatus),
                                }}>
                                    <CardContent sx={{
                                        height: 90, border: "1px solid #E3E3E3", borderRadius: "1px", padding: "3px 5px 5px 15px", width: "100%", color: getSlotColor(slot)
                                    }}>
                                        <Grid>
                                            {slot ? (
                                                <>
                                                    <Typography variant='subtitle2' sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 16 }}>
                                                        {new Date(slot.interviewDate).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })}
                                                    </Typography>
                                                    <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Grid sx={{ flexBasis: "50%" }}>
                                                            <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>Start time</Typography>
                                                            <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 14 }}>
                                                                {startTimeFormatted}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid sx={{ flexBasis: "50%" }}>
                                                            <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>End time</Typography>
                                                            <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 14 }}>
                                                                {calculateEndTime(startTimeFormatted, slot.interviewDuration)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography variant="subtitle2" sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 12 }}>
                                                        Duration: {formatDuration(slot.interviewDuration)}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography variant='subtitle2' sx={{ fontFamily: "Roboto", fontWeight: 600, fontSize: 16, color: '#AAA' }}></Typography>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            );

                            return isFinalized ? (
                                <Tooltip key={key} title="Scheduled Interview Slot" placement="top" arrow>
                                    {cardContent}
                                </Tooltip>
                            ) : (
                                cardContent
                            );
                        })}

                        <Card sx={{
                            flexBasis: "45%",
                            boxShadow: "none",
                            '& .MuiCardContent-root:last-child': {
                                paddingBottom: '0 !important',
                            }
                        }}>
                            <CardContent
                                sx={{
                                    height: 90,
                                    borderRadius: "1px",
                                    padding: "0px",
                                    overflow: "hidden",
                                    position: "relative",
                                    display: "flex"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        position: "relative",
                                        border: "2px solid #E3E3E3",
                                        backgroundColor: "#F8F8F8"
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontFamily: "Roboto",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "18.75px",
                                            color: "#353535",
                                            margin: "7px"
                                        }}
                                    >
                                        {getTruncatedText(availability?.description || "")}
                                        {availability?.description?.length > 185 && (
                                            <Link
                                                onClick={() => handleOpen(availability?.description)}
                                                sx={{
                                                    marginLeft: "5px",
                                                    cursor: "pointer",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Read More
                                            </Link>
                                        )}
                                    </Typography>
                                </Box>
                                <EditOutlinedIcon
                                    onClick={() => handleOpenEdit(availability)}
                                    sx={{ alignSelf: "center", width: "75px", color: "#4A57A3", fontSize: 35, marginBottom: "63px" }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            );
        });
    };


    return (
        <Box
            data-testid='interview-details'
            sx={{ margin: "10px" }}
        >
            {renderSlotCard()}
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{ bgcolor: 'background.paper', padding: 4, borderRadius: 2, width: 832 }}>
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>Notes</Typography>
                    <Typography variant="body1" sx={{ marginBottom: 4 }}>{selectedNotes}</Typography>
                    <Button onClick={handleClose} variant="contained" sx={{ marginLeft: "330px", backgroundColor: "#4A57A3", textTransform: "none" }}>Close</Button>
                </Box>
            </Modal>

            <ModalForProposeTimeSlot
                scheduleInterviewOpen={openEdit}
                setScheduleInterviewOpen={setOpenEdit}
                setAvailabilities={props.setAvailabilities}
                candidateName={props.candidateName}
                candidateEmail={props.candidateEmail}
                slotId={slotId}
                availabilities={selectedSlots}
                description={selectedNotes}
                modifyMode={props.modifyMode}
                resumeVLNStatus={props.resumeVLNStatus}
                id={props.interviewSubmissionID}
                updateDataAgain={props.updateDataAgain}
                setUpdateDataAgain={props.setUpdateDataAgain}
                isFromInterviewDetail={true}
                setDescription={setSelectedNotes}
            />
        </Box>
    )
}

export default InterviewDetail
